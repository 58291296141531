import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				SunnySide Realty
			</title>
			<meta name={"description"} content={"Перетворення будинків на дім"} />
			<meta property={"og:title"} content={"SunnySide Realty"} />
			<meta property={"og:description"} content={"Перетворення будинків на дім"} />
			<meta property={"og:image"} content={"https://lixorevia.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://lixorevia.com/img/house.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://lixorevia.com/img/house.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://lixorevia.com/img/house.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://lixorevia.com/img/house.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://lixorevia.com/img/house.png"} />
			<meta name={"msapplication-TileImage"} content={"https://lixorevia.com/img/house.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="space-between"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						lg-text-align="center"
						text-transform="uppercase"
						margin="0px 0px 8px 0px"
						color="--grey"
						font="--base"
						letter-spacing="2px"
					>
						Про компанію SunnySide Realty.
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Наша історія
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
				Заснована на вірі в те, що кожен заслуговує на місце, яке можна назвати своїм, компанія SunnySide Realty виросла з невеликого агентства у визнаного лідера в сфері нерухомості. Ми починали з простої місії: спростити процес купівлі-продажу нерухомості та зробити його доступним для всіх. За ці роки ми допомогли незліченній кількості клієнтів знайти свої ідеальні будинки, продати нерухомість за вигідною ціною та забезпечити ідеальну оренду. Наш шлях побудований на довірі, наполегливій праці та непохитній відданості нашим клієнтам.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://lixorevia.com/img/4.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Наша команда
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Основою компанії SunnySide Realty є наша динамічна команда професіоналів у сфері нерухомості. Кожен член команди має багатий досвід і заразливий ентузіазм у сфері нерухомості. Наші агенти - це не лише досвідчені перемовники та аналітики ринку, вони є вашими сусідами та членами громади, які розуміють важливість дому для історії вашого життя.
					</Text>
				</Box>
				<Image src="https://lixorevia.com/img/5.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://lixorevia.com/img/6.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Чому обирають SunnySide Realty
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Приєднуйтесь до нас у SunnySide Realty, де ваші прагнення до нерухомості стають нашою місією. Ми віримо у створення стосунків на все життя, а не лише укладання угод. Обираючи нас, ви не просто знаходите нерухомість - ви створюєте свою історію з командою, яка цінує ваші мрії та невтомно працює над їх здійсненням.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-29">
			<Override
				slot="SectionContent"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
				flex-wrap="wrap"
				md-flex-direction="column"
				sm-min-width="280px"
			/>
			<Box width="20%" sm-width="100%" md-margin="0px 0px 36px 0px">
				<Text margin="0px 0px 0px 0px" font="--lead" color="--dark" sm-margin="0px 0px 20px 0px">
				Що нас відрізняє
					<br />
				</Text>
			</Box>
			<Box width="80%" sm-width="100%" md-width="100%">
				<Box
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
				>
					<Text color="--dark" letter-spacing="-.7px" margin="0px 0px 8px 0px" font="--headline3">
					Клієнтоорієнтований підхід: 
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					Ми прислухаємося до ваших потреб та вподобань, щоб гарантувати, що наші послуги повністю відповідають вашим очікуванням.
					</Text>
				</Box>
				<Box
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
					Прозорі процеси: 
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					Кожен крок вашої подорожі у сфері нерухомості є чітким і зрозумілим, без жодних несподіванок на цьому шляху.
					</Text>
				</Box>
				<Box
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
					Інноваційні рішення: 
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					Ми використовуємо новітні технології та стратегії, щоб забезпечити вам найкращі результати.
					</Text>
				</Box>
				<Box width="100%" border-color="--color-lightD2" margin="0px 0px 0px 0px">
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
					Прагнення до задоволення: 
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					Ваше щастя - наш успіх. Ми прагнемо перевершити очікування в кожній взаємодії.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});